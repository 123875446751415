import { Injectable } from '@angular/core';

import * as io from 'socket.io-client';

import feathers from '@feathersjs/feathers';
import feathersSocketIOClient from '@feathersjs/socketio-client';
import feathersAuthClient from '@feathersjs/authentication-client';

/**
 * Simple wrapper for feathers
 */
@Injectable()
export class Feathers {
  private feathers = feathers();                      // init feathers
  private socket = io('photobooth.hec.ca:3031');      // init socket.io

  constructor() {
    this.feathers
      .configure(feathersSocketIOClient(this.socket))    // add socket.io plugin
      .configure(feathersAuthClient({                   // add authentication plugin
        header: 'Authorization', // the default authorization header for REST
        prefix: '', // if set will add a prefix to the header value. for example if prefix was 'JWT' then the header would be 'Authorization: JWT eyJ0eXAiOiJKV1QiLCJhbGciOi...'
        storageKey: 'feathers-jwt',   // the key to store the accessToken in localstorage or AsyncStorage on React Native
        storage: window.localStorage // Passing a WebStorage-compatible object to enable automatic storage on the client.
      }));
  }

  public service(name: string) {
    return this.feathers.service(name);
  }

  public authenticate(credentials?): Promise<any> {
    return this.feathers.authenticate(credentials);
  }

  public logout() {
    return this.feathers.logout();
  }
}
