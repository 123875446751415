import { HttpService } from './../../services/http.service';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Feathers } from '../../services/feathers.service';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  form: FormGroup = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
  });

  @Input() error: string | null;
  hide = true;

  constructor(private feathers: Feathers, private router: Router) { }

  submit() {
    this.error = null;
    if (!this.form.value.username || !this.form.value.password) {
      this.error = 'Username or password invalid !';
      return;
    } else {
      // try to authenticate with feathers
      this.feathers.authenticate({
        strategy: 'local',
        email: this.form.value.username,
        password: this.form.value.password
      })
        // navigate to base URL on success
        .then((res) => {
          this.router.navigate(['/']);
        })
        .catch(err => {
          this.error = 'Username or password invalid !';
        });
    }
  }
}
