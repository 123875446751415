import { HttpService } from './../../services/http.service';
import { Component, OnInit } from '@angular/core';

const HOSTNAME_CUBE = 'photobooth-acc.hec.ca:3001';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  cubeProjects: Array<any>;
  albumProjects: Array<any>;
  selectedCubeProjects = [];
  selectedAlbumProjects = [];

  constructor(private httpService: HttpService) { }

  ngOnInit() {
    this.updateCubeProject();
    this.updateAlbumProject();
  }

  private updateCubeProject() {
    this.httpService.callGetRequestWithAuth(HOSTNAME_CUBE + '/projects')
      .then((res: any) => {
        this.cubeProjects = res.data;
      });
  }

  private updateAlbumProject() {
    this.httpService.callGetRequestWithAuth(HOSTNAME_CUBE + '/projects/album')
      .then((res: any) => {
        this.albumProjects = res;
      });
  }

  exportProject() {
    console.log(this.getSelectedProjectIds());

    this.httpService.callPostRequestWithAuth(HOSTNAME_CUBE + '/export', { projectIds: this.getSelectedProjectIds() })
      .then((res: any) => {
        console.log('Exportating Succes');
        this.updateAlbumProject();
      });
  }

  importProject() {
    console.log(this.getSelectedProjectNames());

    this.httpService.callPostRequestWithAuth(HOSTNAME_CUBE + '/import', {
      projectNames: this.getSelectedProjectNames(),
      isUploadFiles: true,
      operationDelay: 2000
    })
      .then((res: any) => {
        console.log('Importating Succes');
        this.updateCubeProject();
      });
  }

  downloadProject() {
    this.httpService.callDownloadFile(HOSTNAME_CUBE + '/export/download', { projectIds: this.getSelectedProjectIds() })
      .then((res: any) => {
        console.log(res);
      });
  }

  getSelectedProjectIds() {
    const projectIds = [];
    this.selectedAlbumProjects.forEach(project => {
      projectIds.push(project.id);
    });
    return projectIds;
  }

  getSelectedProjectNames() {
    const projectNames = [];
    this.selectedCubeProjects.forEach(project => {
      projectNames.push(project.name);
    });
    return projectNames;
  }

}
