import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class HttpService {

  private authToken: string;

  constructor(private http: HttpClient) { }

  setAuthToken(authToken: string) {
    this.authToken = authToken;
  }

  async callGetRequestWithAuth(url: string, headers?: any) {
    const authStr = 'Bearer ' + this.authToken;
    headers = { Connection: 'keep-alive', Authorization: authStr };

    return new Promise((resolve, reject) => {
      this.http.get(url, { headers })
        .subscribe(
          (response: any) => {
            resolve(response);
          },
          (error) => {
            console.log('Erreur ! : ' + error);
            reject(error);
          }
        );
    });
  }

  async callPostRequestWithAuth(url: string, data: any, headers?: any) {
    const authStr = 'Bearer ' + this.authToken;
    headers = { Connection: 'keep-alive', Authorization: authStr };

    return new Promise((resolve, reject) => {
      this.http.post(url, data, { headers })
        .subscribe(
          (response: any) => {
            resolve(response);
          },
          (error) => {
            console.log('Erreur ! : ', error);
            reject(error);
          }
        );
    });
  }

  async callDownloadFile(url: string, data: any, headers?: any) {
    const authStr = 'Bearer ' + this.authToken;
    headers = { Connection: 'keep-alive', Authorization: authStr, responseType: 'blob' as 'json' };

    return new Promise((resolve, reject) => {
      this.http.get<Blob>(url, { headers, params: data })
        .subscribe(
          (response: any) => {
            resolve(response);
          },
          (error) => {
            console.log('Erreur ! : ', error);
            reject(error);
          }
        );
    });
  }
}
